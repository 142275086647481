html * {
  position: relative;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
  margin: 0;
  -ms-touch-action: none;
}
body,
html {
  height: 100%;
  background: white;
  overflow: hidden;
  touch-action: manipulation;
}

img,
p,
div {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.bottom:before {
  content: "";
  height: 100%;
  width: 0;
  display: inline-block;
  vertical-align: bottom;
}
.middle:before {
  content: "";
  height: 100%;
  width: 0;
  display: inline-block;
  vertical-align: middle;
}
.middle > *,
.center > * {
  display: inline-block;
  vertical-align: middle;
}
.center {
  text-align: center;
}
.link {
  cursor: pointer;
  transition: opacity 0.2s;
}
.link:hover {
  opacity: 0.5;
}

.big {
  font-weight: 800;
  font-size: 1.5em;
  line-height: 1;
  margin: 1em 0;
}
.small {
  font-weight: 400;
  font-size: 0.8em;
}
form {
  text-align: left;
  padding: 10px;
  max-width: 600px;
  margin: 0 auto;
}
input,
select {
  width: 100%;
  display: block;
  background: #f8f8f8;
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px;
  font-size: 0.9em;
  border: 1px solid #ccc;
}

label {
  display: block;
  text-align: left;
  cursor: pointer;
  margin: 0;
  padding: 5px;
  line-height: 1.5;
  width: 100%;
}
label > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
label > input[type="checkbox"] + span {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin: -5px 5px 0 0;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.3);
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px solid white;
}
label > input[type="checkbox"]:checked + span:before {
  content: "";
  display: block;
  width: 60%;
  height: 30%;
  margin-left: 15%;
  margin-top: 15%;
  border-left: solid;
  border-bottom: solid;
  border-width: 0.2em;
  transform: rotate(-45deg);
}
label > input[type="checkbox"]:focus + span {
  -webkit-box-shadow: 0px 0px 5px #4d90fe;
  box-shadow: 0px 0px 5px #4d90fe;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.7;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.7;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.7;
}

.half {
  display: inline-block;
  width: 50%;
}
.third {
  display: inline-block;
  width: 33.33%;
}

.pad {
  padding: 1em;
}
.abs {
  position: absolute;
}
.full {
  width: 100%;
  height: 100%;
}
.modal-mask {
  font-size: 18px;
}
.alert-button {
  padding: 0.25em 1em !important;
}

button {
  font-size: 1em;
  padding: 0.25em 0.5em;
}
/*
@media (max-width: 800px) {
	.half, .third {
		width:100%;
	}
}*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5em;
}
