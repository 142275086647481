.top-list {
  width: 100%;
  text-align: left;
}
.top-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.top-row > span {
  padding: 0.1em 0.2em;
}
.top-name {
  flex-grow: 1;
}
