body {
  background: #3a99d8 url(assets/bg.png);
  background-size: contain;
  background-position: center;
  font-size: 40px;

  /*font-family: "VT323", monospace;
  font-family: "Open Sans", sans-serif;*/
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.lang-select {
  position: absolute;
  font-size: 20px;
  top: 1em;
  right: 1em;
  list-style: none;
  cursor: pointer;
  color: white;
}
.lang-select:hover {
  text-decoration: underline;
}

a {
  color: inherit;
  text-decoration: underline;
}

#wrapper {
  width: 1080px;
  height: 1600px;
  color: white;
}

#peli {
}

#peli > *,
#bg > *,
#astiat > * {
  position: absolute;
  pointer-events: none;
}
.hitarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*border: 1px solid red;*/
  pointer-events: all;
}
#astiat > * {
}

#astiat > *.active {
}

#progress {
  width: 400px;
  height: 400px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.tooltip {
  font-size: 30px;
  position: absolute;
  background: white;
  border-radius: 0.2em;
  padding: 1em;
  bottom: calc(100% + 2em); /* tip border radius x 2 */
  transform: skewY(30deg);
  transform-origin: 0;
  left: 50%;
  opacity: 0;
  transition: all 0.1s;
  pointer-events: none;
  color: black;
}

.tip {
  border: 1.5em solid white;
  border-right-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: calc(100% - 0.2em); /* tooltip border radius */
  left: 0;
  transform: scaleX(0.4);
  transform-origin: 0;
}

#astiat > *.active .tooltip {
  opacity: 1;
}
#draggables {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#draggables > div {
  position: absolute;
}
#draggables > div > img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  left: -150px;
  top: -150px;
}

.scoreDisplay {
  font-size: 3em;
  font-weight: 700;
}
.toplist {
  width: 600px;
}

.boksi {
  background: rgba(0, 50, 100, 0.8);
  overflow: auto;
}

button {
  color: white;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  padding: 0.5em 2em;
  background: rgba(150, 220, 255, 0.5);
  border: 2px solid white;
  border-radius: 2em;
  font-weight: 800;
  margin: 0.25em;
  text-transform: uppercase;
}
